import { buildTranslate } from 'locales'
import flatMap from 'lodash/flatMap'
import {
  CouponPoolGenerationType,
  CouponPoolType,
  Fields as CouponPool,
} from 'models/CouponPool'
import { Fields as MerchantFields } from 'models/Merchant'
import { Fields as MerchantCouponPool } from 'models/MerchantCouponPool'
import {
  Fields as RedeemTemplate,
  RedeemTemplateSubtype,
  RedeemType,
} from 'models/RedeemTemplate'
import { ValidationTypes } from 'models/Validation'
import moment from 'moment'
import { I18n } from 'react-redux-i18n'
import { getFormattedDate } from 'utilities/date'
import { FormModel } from './Builder/FormModel'
import { InstoreRedemptionType, RedeemCategory, RedeemTypeItem } from './types'

const t = buildTranslate('redeem_manager.helpers')

export const REDEEM_CATEGORIES: RedeemCategory[] = [
  {
    key: 'free_item',
    items: [
      {
        key: 'get_a_free_item',
        types: ['manual/item'],
        subtype: 'item',
      },
      {
        key: 'get_a_free_item',
        types: ['manual/item'],
        subtype: null,
      },
    ],
  },
  {
    key: 'discount',
    items: [
      {
        key: 'get_%_off_purchase',
        types: ['manual/percent', 'automatic/percent'],
        subtype: 'purchase',
      },
      {
        key: 'get_%_off_purchase',
        types: ['manual/percent', 'automatic/percent'],
        subtype: null,
      },
      {
        key: 'get_%_off_item',
        types: ['manual/percent'],
        subtype: 'item',
      },
      {
        key: 'get_$_off_purchase',
        types: ['manual/amount', 'automatic/amount'],
        subtype: 'purchase',
      },
      {
        key: 'get_$_off_purchase',
        types: ['manual/amount', 'automatic/amount'],
        subtype: null,
      },
      {
        key: 'get_$_off_item',
        types: ['manual/amount'],
        subtype: 'item',
      },
    ],
  },
  {
    key: 'buy_one_get_one',
    forOrdering: true,
    items: [
      {
        key: 'buy_one_get_free_one',
        types: ['manual/item'],
        subtype: 'bogo/item',
      },
      {
        key: 'buy_item_get_%_off_item',
        types: ['manual/percent'],
        subtype: 'bogo/item',
      },
      {
        key: 'buy_item_get_$_off_item',
        types: ['manual/amount'],
        subtype: 'bogo/item',
      },
    ],
  },
  {
    key: 'buy_x_get_discount',
    forOrdering: true,
    items: [
      {
        key: 'buy_x_get_%_off_purchase',
        types: ['manual/percent', 'automatic/percent'],
        subtype: 'bogo/purchase',
      },
      {
        key: 'buy_x_get_$_off_purchase',
        types: ['manual/amount', 'automatic/amount'],
        subtype: 'bogo/purchase',
      },
    ],
  },
  {
    key: 'hidden_menu',
    forOrdering: false,
    items: [
      {
        key: 'hidden_menu',
        types: ['experience/hidden_menu'],
        subtype: null,
      },
    ],
    isNonDiscount: true,
  },
  {
    key: 'golden_ticket',
    forOrdering: false,
    items: [
      {
        key: 'golden_ticket',
        types: ['experience/golden_ticket'],
        subtype: null,
      },
    ],
    isNonDiscount: true,
    isDisabled: false,
  },
  {
    key: 'bonus_points',
    forOrdering: false,
    items: [
      {
        key: 'bonus_points',
        types: ['points/static'],
        subtype: null,
      },
    ],
    isNonDiscount: true,
    isDisabled: false,
  },
]
export const TYPE_ITEMS: RedeemTypeItem[] = flatMap(
  REDEEM_CATEGORIES,
  ({ items }) => items
)

export function getTemplateKeyForType(
  type: RedeemType,
  subtype: RedeemTemplateSubtype
): string | null {
  return (
    TYPE_ITEMS.find(
      item => item.types.includes(type) && item.subtype === subtype
    )?.key ?? null
  )
}

export function getRedeemTemplateType(
  typeItem: RedeemTypeItem,
  redemptionType: InstoreRedemptionType
): RedeemType {
  const { types } = typeItem
  const isAuto = redemptionType === 'automatic_cash_back'

  const found = types.find(t => {
    if (isAuto) {
      return t === 'automatic/amount' || t === 'automatic/percent'
    }
    return t === 'manual/amount' || t === 'manual/percent'
  })
  return found || types[0]
}

export function getRedeemCategories(
  orderingEnabled: boolean
): RedeemCategory[] {
  return REDEEM_CATEGORIES.filter(
    ({ forOrdering }) => !forOrdering || orderingEnabled
  )
}

export function getDiscountCategories(
  orderingEnabled: boolean
): RedeemCategory[] {
  return getRedeemCategories(orderingEnabled).filter(
    ({ isNonDiscount }) => !isNonDiscount
  )
}

export function getNonDiscountCategories(
  orderingEnabled: boolean,
  exclude: string[] = []
): RedeemCategory[] {
  return getRedeemCategories(orderingEnabled).filter(
    ({ isNonDiscount, key }) => !!isNonDiscount && !exclude.includes(key)
  )
}

export function formatCurrency(value: number | null): string {
  const newValue = value || 0
  return I18n.l(newValue || 0, {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: newValue % 1 !== 0 ? 2 : 0,
    maximumFractionDigits: 2,
  })
}

export function getDiscount(
  type: RedeemType,
  discount: number | null
): string | null {
  if (type === 'manual/percent' || type === 'automatic/percent') {
    return t('percent', {
      value: discount,
    })
  }

  if (type === 'manual/amount' || type === 'automatic/amount') {
    return formatCurrency(discount)
  }

  return null
}

export function getCostToBusiness(
  type: RedeemType,
  discount: number | null
): string | null {
  if (type === 'manual/amount' || type === 'automatic/amount') {
    return null
  }

  return formatCurrency(discount)
}

export function getInstoreRedemptionType(
  template: RedeemTemplate
): InstoreRedemptionType {
  const { type: templateType, uses_coupon_codes } = template

  if (uses_coupon_codes) {
    return InstoreRedemptionType.COUPON_CODES
  }

  if (
    templateType === 'automatic/amount' ||
    templateType === 'automatic/percent'
  ) {
    return InstoreRedemptionType.CASH_BACK
  }

  return InstoreRedemptionType.MANAGER_COMP
}

export function generateTypeItem(
  template: RedeemTemplate
): RedeemTypeItem | null {
  return (
    TYPE_ITEMS.find(
      ({ types, subtype }) =>
        template.subtype === subtype && types.includes(template.type)
    ) || null
  )
}

export function mapCouponPoolToSelect(cp: CouponPool | MerchantCouponPool) {
  const date = getFormattedDate(moment(cp.created_at), 'daily')
  let label = `${date} - ${cp.type}`
  if (cp.generation_type === CouponPoolGenerationType.STATIC) {
    label = `${label} - ${cp.static_code}`
  }
  return {
    id: cp.id,
    label: label,
    value: cp.id,
    generation_type: cp.generation_type,
    type: cp.type,
    static_code: cp.static_code,
  }
}

export function generateFormModel(
  merchant: MerchantFields | null,
  template?: RedeemTemplate,
  couponPools?: CouponPool[] | null
): FormModel {
  if (!template) {
    return {
      typeItem: null,
      name: '',
      description: '',
      discount: '',
      perceivedValue: '',
      availableOnline: !!merchant?.ordering_enabled,
      availableInstore: !merchant?.online_only,
      instoreRedemptionType: isIndirectLoyaltyIntegrationMerchant(merchant)
        ? InstoreRedemptionType.COUPON_CODES
        : InstoreRedemptionType.MANAGER_COMP,
      discountedProductIds: [],
      requiredProductIds: [],
      instoreDiscountedProductIds: [],
      instoreRequiredProductIds: [],
      onlineDiscountedProductIds: [],
      onlineRequiredProductIds: [],
      availableCustomerSupport: true,
      couponCodes: {
        generationType: merchant?.supports_loyalty_integration
          ? CouponPoolGenerationType.DYNAMIC
          : CouponPoolGenerationType.VARIABLE,
        staticCode: '',
        type: CouponPoolType.RAW,
        uploadColumn: null,
      },
      images: {
        [ValidationTypes.RedeemAdvertising]: {
          id: null,
          uploading: false,
          url: '',
        },
        [ValidationTypes.RedeemDetail]: {
          id: null,
          uploading: false,
          url: '',
        },
        [ValidationTypes.RedeemIndex]: {
          id: null,
          uploading: false,
          url: '',
        },
      },
      longDescription: '',
      selectedCouponPools: [],
    }
  }

  const { redemption_venue } = template
  const availableOnline =
    redemption_venue === 'all' || redemption_venue === 'online'
  const availableInstore =
    !merchant?.online_only &&
    (redemption_venue === 'all' || redemption_venue === 'instore')
  return {
    typeItem: generateTypeItem(template),
    name: template.name,
    description: template.description || '',
    discount: template.discount?.toString() || '',
    perceivedValue: template.perceived_value?.toString() || '',
    availableOnline,
    availableInstore,
    instoreRedemptionType: getInstoreRedemptionType(template),
    discountedProductIds: template.discounted_product_ids || [],
    requiredProductIds: template.required_product_ids || [],
    instoreDiscountedProductIds:
      template.product_ids?.instore?.discounted || [],
    instoreRequiredProductIds: template.product_ids?.instore?.required || [],
    onlineDiscountedProductIds: template.product_ids?.digital?.discounted || [],
    onlineRequiredProductIds: template.product_ids?.digital?.required || [],
    availableCustomerSupport: template.granted_program_visible,
    hiddenMenuKey: template.unhides_category_ids
      ? [template.unhides_category_ids.toString()]
      : undefined,
    hiddenMenuName: template.category_name,
    couponCodes: {
      generationType:
        couponPools?.[0]?.generation_type ?? CouponPoolGenerationType.VARIABLE,
      staticCode:
        couponPools?.[0]?.generation_type === CouponPoolGenerationType.STATIC &&
        couponPools?.[0]?.static_code
          ? couponPools?.[0]?.static_code
          : '',
      type: couponPools?.[0]?.type ?? CouponPoolType.RAW,
      uploadColumn: null,
    },
    images: {
      [ValidationTypes.RedeemAdvertising]: {
        id: template.images?.advertising_image?.file_upload_id || null,
        uploading: false,
        url: template.images?.advertising_image?.small || '',
      },
      [ValidationTypes.RedeemDetail]: {
        id: template.images?.detail_image?.file_upload_id || null,
        uploading: false,
        url: template.images?.detail_image?.small || '',
      },
      [ValidationTypes.RedeemIndex]: {
        id: template.images?.index_image?.file_upload_id || null,
        uploading: false,
        url: template.images?.index_image?.small || '',
      },
    },
    longDescription: template.long_description || '',
    numberOfPoints: template.number_of_points,
    selectedCouponPools: !!couponPools?.length
      ? couponPools.map(cp => mapCouponPoolToSelect(cp))
      : [],
  }
}

export function getDiscountedProductsLabel(
  isItemType: boolean = false,
  subtype?: RedeemTemplateSubtype
) {
  const hasRequiredInput =
    subtype === 'bogo/item' || subtype === 'bogo/purchase'

  let labelKey = 'default_label'
  if (isItemType) {
    labelKey = hasRequiredInput ? 'they_for_free' : 'free_items'
  } else if (hasRequiredInput) {
    labelKey = 'they_discount'
  }

  return t(`discounted.${labelKey}`)
}

export function rewardIsDisplayable(template: RedeemTemplate | null) {
  const isBonusPoints = template?.type === 'points/static'
  return !isBonusPoints
}

export function splitByComma(value) {
  return value.trim().split(/\s*,\s*/)
}

export function isNDR(templateType: RedeemType): boolean {
  const ndr = REDEEM_CATEGORIES.find(({ isNonDiscount, items }) => {
    if (!isNonDiscount) return null
    return items.find(item => item.types.includes(templateType))
  })
  return !!ndr
}

export function isDirectLoyaltyIntegrationMerchant(
  merchant: MerchantFields | null
) {
  return ['toast', 'qu'].includes(merchant?.loyalty_integration_partner || '')
}

export function isIndirectLoyaltyIntegrationMerchant(
  merchant: MerchantFields | null
) {
  return (
    !isDirectLoyaltyIntegrationMerchant(merchant) &&
    (merchant?.supports_loyalty_integration || false)
  )
}
