import { Text } from '@thanx/uikit/text'
import { defaultTheme, useStyletron } from '@thanx/uikit/theme'
import React from 'react'
import { Col } from 'react-bootstrap-five'
import { Link } from 'react-router-dom'

type ButtonT = {
  className: string
  text: string
  icon: string
  url?: string
  onClick?: () => void
}

const Button: React.FC<ButtonT> = ({ className, icon, url, text, onClick }) => {
  const [css] = useStyletron()

  if ((url && onClick) || (!url && !onClick)) {
    throw new Error('Either url or onClick must be provided but not both')
  }

  const buttonStyles = css({
    borderRadius: '4px',
    borderWidth: '1px',
    boxShadow: 'none',
    borderStyle: 'solid',
    transition: 'border-color 0.2s ease',
    borderColor: defaultTheme.colors.grey30,
    ':hover': {
      borderColor: defaultTheme.colors.grey90,
    },
  })

  const buttonClassName = `w-100 p-m white-bkg text-left d-flex align-content-center ${buttonStyles}`

  return (
    <Col className={`mb-xs ${className}`} md={true} sm={12}>
      {url ? (
        <Link
          className={css({
            textDecoration: 'none !important',
          })}
          to={url}
        >
          <button className={buttonClassName}>
            <img className="mr-s" src={icon} alt={text} />
            <Text.BodyText3>{text}</Text.BodyText3>
          </button>
        </Link>
      ) : (
        <button className={buttonClassName} onClick={onClick}>
          <img className="mr-s" src={icon} alt={text} />
          <Text.BodyText3>{text}</Text.BodyText3>
        </button>
      )}
    </Col>
  )
}

export default Button
