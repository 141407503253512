import inactiveTiersIcon from 'assets/images/campaigns/tiers_inactive.png'
import { buildTranslate } from 'locales'
import { Fields as Tier } from 'models/Tier'
import React from 'react'
import { Row } from 'react-bootstrap'
import InactiveContent from './InactiveContent'
import TierInfo from './TierInfo'

const t = buildTranslate('looker.tiers')

type Props = {
  tiers: Tier[]
}

const Tiers: React.FC<Props> = ({ tiers }) => {
  if (!tiers || tiers.length === 0) {
    return (
      <InactiveContent
        title={t('title')}
        subtext={t('subtext')}
        icon={inactiveTiersIcon}
      />
    )
  }

  const bronzeTier = tiers.filter(tier => {
    return tier.type === 'tier_bronze'
  })[0]
  const silverTier = tiers.filter(tier => {
    return tier.type === 'tier_silver'
  })[0]
  const goldTier = tiers.filter(tier => {
    return tier.type === 'tier_gold'
  })[0]

  const bronzeIcon = bronzeTier?.icon_image_urls?.small || ''
  const silverIcon = silverTier?.icon_image_urls?.small || ''
  const goldIcon = goldTier?.icon_image_urls?.small || ''

  return (
    <div>
      <div className="font-size-24 margin-bottom-large bold">{t('title')}</div>
      <Row className="display-flex flex-row">
        <TierInfo tier={bronzeTier} icon={bronzeIcon} />
        <TierInfo tier={silverTier} icon={silverIcon} />
        <TierInfo tier={goldTier} icon={goldIcon} />
      </Row>
    </div>
  )
}

export default Tiers
