import { useStyletron } from '@thanx/uikit/theme'
import SectionHeader from 'components/Navbar/Bar/SectionHeader'
import { buildTranslate } from 'locales'
import React from 'react'
import { I18n } from 'react-redux-i18n'
import { useAbility } from 'utilities/ability'
import NavLink from '../Bar/NavLink'

const t = buildTranslate('nav.customers_menu')

type Props = {
  isLeftNav?: boolean
  onClick?: VoidFunction
}

const CustomersContent = (props: Props) => {
  const { isLeftNav = false, onClick } = props
  const ability = useAbility()
  const [css] = useStyletron()

  return (
    <>
      {!isLeftNav && (
        <SectionHeader
          text={t('sections.manage_customers.title')}
          iconName="manageCustomers"
        />
      )}
      <NavLink
        isLeftNav={isLeftNav}
        onClick={onClick}
        text={t('sections.manage_customers.all_customers')}
        link="/users"
        disabled={ability.cannot('manage', 'Users')}
      />
      {!isLeftNav && (
        <>
          <div className="mb-m" />
          <SectionHeader
            text={t('sections.activity.title')}
            iconName="chartLine"
          />
        </>
      )}
      <NavLink
        isLeftNav={isLeftNav}
        onClick={onClick}
        link="/reports/daily_activity"
        text={t('sections.activity.daily_activity')}
        disabled={ability.cannot('access', 'LocationReports')}
      />
      <NavLink
        isLeftNav={isLeftNav}
        onClick={onClick}
        link="/reports/revenue"
        text={t('sections.activity.revenue')}
        disabled={ability.cannot('access', 'LocationReports')}
      />
      <NavLink
        isLeftNav={isLeftNav}
        onClick={onClick}
        link="/reports/purchases"
        text={t('sections.activity.purchases')}
        disabled={ability.cannot('access', 'LocationDetailReports')}
      />
      <NavLink
        isLeftNav={isLeftNav}
        onClick={onClick}
        text={t('sections.activity.signups')}
        link="/reports/customer"
        disabled={ability.cannot('manage', 'Insights')}
      />
      {!isLeftNav && (
        <>
          <div className="mb-m" />
          <SectionHeader
            text={t('sections.customer_lifecycle.title')}
            iconName="cycle"
          />
        </>
      )}
      <NavLink
        isLeftNav={isLeftNav}
        onClick={onClick}
        text={t('sections.customer_lifecycle.overview')}
        link="/reports/membership_overview"
        disabled={ability.cannot('manage', 'LocationReports')}
      />
      <NavLink
        isLeftNav={isLeftNav}
        onClick={onClick}
        text={t('sections.customer_lifecycle.activation')}
        link="/reports/activation#activation_rate"
        disabled={ability.cannot('manage', 'Insights')}
      />
      <NavLink
        isLeftNav={isLeftNav}
        onClick={onClick}
        text={t('sections.customer_lifecycle.engagement')}
        link="/reports/engagement"
        disabled={ability.cannot('access', 'LocationReports')}
      />
      <NavLink
        isLeftNav={isLeftNav}
        onClick={onClick}
        text={t('sections.customer_lifecycle.retention')}
        link="/reports/retention_rate"
        disabled={ability.cannot('manage', 'Insights')}
      />
      {!isLeftNav && (
        <>
          <div className="mb-m" />
          <SectionHeader text={t('sections.insights.title')} iconName="bulb" />
        </>
      )}
      <NavLink
        isLeftNav={isLeftNav}
        onClick={onClick}
        link="/reports/capture_rate"
        text={t('sections.insights.capture_rate')}
        disabled={ability.cannot('manage', 'OperationalReports')}
      />
      <NavLink
        isLeftNav={isLeftNav}
        onClick={onClick}
        link="/reports/average_check"
        text={t('sections.insights.average_check')}
        disabled={ability.cannot('access', 'LocationDetailReports')}
      />
      <NavLink
        isLeftNav={isLeftNav}
        onClick={onClick}
        link="/reports/frequency"
        text={t('sections.insights.frequency')}
        disabled={ability.cannot('access', 'LocationReports')}
      />
      <div
        className={
          isLeftNav
            ? `my-s mx-m ${css({
                borderRadius: '4px',
                overflow: 'hidden',
              })}`
            : 'mt-m'
        }
      >
        <SectionHeader
          isLeftNav={isLeftNav}
          text={I18n.t('nav.legacy.features')}
          iconName="scroll"
          isLegacy
          learnMore={!isLeftNav}
        />
        <NavLink
          isLeftNav={isLeftNav}
          onClick={onClick}
          text={t('sections.legacy.import_email_subscribers')}
          link="/subscribers/setup/connect"
          disabled={ability.cannot('manage', 'LegacyReports')}
          isLegacy
        />
      </div>
    </>
  )
}

export default CustomersContent
